<template>
  <v-container>
    <div class="header" style="">
      <span>
        <h3>Log Emails</h3>
      </span>
    </div>
    <div style="border-radius:5px;margin-bottom:5px;display:flex;flex-direction:column;">
         <v-container class="">
            <v-row>

                    <v-col >
                        <v-select v-model="sistema" label="Sistema" item-text="nome" item-value="id" 
                            :items="listadesistema" outlined return-object hide-details dense
                            @change="()=>{ requisitarClientes({ativo:true,teste:1,pausado:false,sistema:this.sistema.id})}"
                           >
                        </v-select>
                    </v-col>
                    <v-col >
                        <v-select v-model="cliente" label="Cliente" item-text="nome" item-value="id" 
                            :items="listadeclientes" outlined return-object hide-details dense
                           >
                        </v-select>
                    </v-col>
                    <v-col >
                        <v-select v-model="qtderegistros" label="Qtde Registros" item-text="nome" item-value="qtde" 
                            :items="listaqtdereg" outlined return-object hide-details dense
                           >
                        </v-select>
                    </v-col>
                    <v-col >
                        <v-text-field type="date"  placeholder="Data Inicial Envio" 
                        label="Data Inicial Envio" v-model="dtinicialenvio" outlined hide-details dense></v-text-field>
                        
                    </v-col>
                    <v-col >
                        <v-text-field type="date"  placeholder="Data Final Envio" 
                        label="Data Final Envio" v-model="dtfinalenvio" outlined hide-details dense></v-text-field>
                    </v-col>

                    <v-col >
                        <v-text-field type="text"  placeholder="Email" 
                        label="Email" v-model="email" outlined hide-details dense></v-text-field>
                    </v-col>

            </v-row>

            <v-row style="margin-top:-10px;"> 

                    <v-col style="">
                        <v-row>
                        </v-row>
                    </v-col>

                    <v-col>
                        <v-row>
                            <v-col> 
                                <v-btn small elevation="" @click="() => consultarLogEmail()" color="success" block> Consultar</v-btn>       
                            </v-col>
                            <v-col> 
                                <JsonCSV :data="listadelogs" v-if="listadelogs.length > 0" name="logsEmails.csv">
                                    <div style="background-color:green;width:150px;height:30px;margin-left:10px;display:flex;align-items:center;justify-content:center;font-weight:bold;color:white;border-radius:5px;">
                                        Download 
                                    </div>
                                </JsonCSV>
                            </v-col>
                        </v-row>
                        
                    </v-col>

            </v-row>

          </v-container>
        
        
    </div>

     <v-container class="">
        <v-row>
          <v-col>

                <v-data-table :items="listadelogs" :headers="headerlogs" :items-per-page="20" >
                </v-data-table>

          </v-col>
        </v-row>
      </v-container>

  



  </v-container>
</template>

<script>

import SistemaServico from '@/services/sistema.service.js'
import ClienteServico from '@/services/cliente.service.js'
import SuporteServico from '@/services/suporte.service.js'
import JsonCSV from 'vue-json-csv'

export default {
  name: 'ServidoresSuporte',
  components: {
    JsonCSV
  },
  data(){
    return {
        dtinicialenvio:'',
        dtfinalenvio:'',
        email:'',
        listaqtdereg:[
            {qtde:100,  nome:'Ultimos 100'},
            {qtde:250,  nome:'Ultimos 250'},
            {qtde:500,  nome:'Ultimos 500'},
            {qtde:1000, nome:'Ultimos 1000'},
            {qtde:200,  nome:'Ultimos 2000'},
        ],
        qtderegistros:{qtde:100,  nome:'Ultimos 100'},
        listadeclientes:[],
        cliente:{},
        listadelogs:[],
        headerlogs:[
            { text: 'Id',     value: 'id', width:'5%' },
            { text: 'Data',     value: 'dataenvio', width:'5%' },
            { text: 'Hora',     value: 'horaenvio', width:'5%' },
            { text: 'Destino',     value: 'to', width:'10%' },
            { text: 'Host',     value: 'host', width:'10%' },
            { text: 'Porta',     value: 'port', width:'5%' },
            { text: 'Assunto',     value: 'subject', width:'40%' },
            { text: 'Status',     value: 'status', width:'20%' },
            { text: 'Response',     value: 'response', width:'20%' },
        ],
        sistema:{id:1},
        listadesistema: [],
    }
  },
  methods: {

    async requisitarSistemas(){
        let sistemas = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
        this.listadesistema = sistemas
        console.log('sistemas[0] ',sistemas[0])
        this.sistema = sistemas[0]
        console.log('this.listadesistema ',this.listadesistema)
    },

    async requisitarClientes(dados){
      
      let lclientes = await ClienteServico.buscar(dados).then(res => res.data)
      this.listadeclientes = lclientes
      this.cliente = lclientes[0]

    },

    async consultarLogEmail(){
      
      let dados ={
        dtinicialenvio:this.dtinicialenvio,
        dtfinalenvio:this.dtfinalenvio,
        email:this.email,
        cliente:this.cliente,
        sistema:this.sistema,
        limit:this.qtderegistros
      }
      this.listadelogs = await SuporteServico.consultarLogEmail(dados).then(res => res.data)
      

    },

  },
  async mounted(){
    await this.requisitarSistemas()
    await this.requisitarClientes({ativo:true,teste:1,pausado:false,sistema:this.sistema.id})
  }
}
</script>
